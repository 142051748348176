import { Config } from '@supersonic/config';
import theme from './theme';
import footer from './components/footer';
import { header } from './components/header';
import holidayGadget from './components/holidayGadget';
import cruiseGadget from './components/cruiseGadget';
import crmModal from './components/crmModal';
import crmSignUp from './components/crmSignUp';
import * as process from 'process';
import { ASSETS_PREFIX } from './constants';

const config: Config = {
  title: 'Icelolly config file',
  name: 'Icelolly',
  shortName: 'IL',
  titleSuffix: ' | icelolly.com',
  meta: {
    description:
      "icelolly.com - the best low cost holiday price comparison site. Compare millions of cheap holidays from some of the UK's top travel companies.",
  },
  assetsPrefix: ASSETS_PREFIX,
  domain: process.env.DOMAIN as string,
  theme,
  pages: {},
  blog: {
    urlPrefix: '/en-gb/blog',
    archiveStem: 'view-all',
  },
  bestOfSearch: {
    dealsUrl: process.env.BEST_OF_SEARCH_DEALS_URL as string,
    cookie: process.env.BEST_OF_SEARCH_DEALS_COOKIE as string,
  },
  destinationFinder: {
    destinationFinderApi: process.env.DESTINATION_FINDER_API_URL as string,
    urlStem: `/trip-finder/v2`,
  },
  emailSubscription: {
    emailPreferencesPageUrl: '/emailoffers/preferences',
    emailPreferencesShowTravelProducts: false,
    emailSubscriptionApi: process.env.EMAIL_SUBSCRIPTION_API as string,
    isEmailSubscribedCookieName: 'icelolly-email-subscribed',
    isEmailPopupShownCookieName: 'icelolly-email-modal-shown',
  },
  holidaysResultsPage: {
    brokerApi: process.env.BROKER_API_URL as string,
    advertisingApi: process.env.ADVERTISING_API_URL as string,
    site: 'ilm',
    resultsPerPage: 15,
    title: 'Holiday Results',
    urlStem: `holiday-search/v2/results`,
    interstitialUrl: `holiday-search/v2/interstitial`,
  },
  ukResultsPage: {
    urlStem: '/uk-search/v2',
    ukBrokerApi: process.env.UK_BROKER_API_URL as string,
  },
  holidaysInterstitialPage: {
    logo: 'icelolly',
    image: 'https://img.icelolly.com/interstitial/interstitial-IL-update.jpg',
  },
  carHireResultsPage: {
    urlStem: '/en-gb/car-hire/results',
    travelBrokerApi: process.env.TRAVEL_BROKER_API_URL as string,
    resultsApiUrl: process.env.CAR_HIRE_RESULTS_API_URL as string,
    carHireAggregateApiUrl: process.env.CAR_HIRE_AGGREGATE_API_URL as string,
    imageCDNUrl: process.env.ITG_CDN_URL as string,
    interstitialUrl: '/en-gb/car-hire/interstitial',
  },
  carHireInterstitialPage: {
    logo: 'icelolly',
    image: `${
      process.env.ITG_CDN_URL as string
    }/car-hire/interstitial/carhire.png`,
  },
  topDeals: {
    topDealsApi: process.env.TOP_DEALS_API_URL as string,
    site: 'ilm',
  },
  cruise: {
    gadgetSourceId: '134054',
    linkSourceId: '134055',
  },
  components: {
    header,
    footer,
    holidayGadget,
    cruiseGadget,
    crmModal,
    crmSignUp,
  },
  social: {
    facebook: {
      handle: 'icelollyholiday',
      link: 'https://www.facebook.com/icelollyholidays',
      name: 'Facebook',
    },
    twitter: {
      handle: 'icelollyholiday',
      link: 'https://twitter.com/icelollyholiday',
      name: 'X',
    },
    instagram: {
      handle: 'icelollyholiday',
      link: 'https://www.instagram.com/icelollyholiday',
      name: 'Instagram',
    },
    youtube: {
      handle: 'icelollyholiday',
      link: 'https://www.youtube.com/user/icelollyholiday',
      name: 'YouTube',
    },
    tiktok: {
      handle: 'icelollyholiday',
      link: 'https://www.tiktok.com/@icelollyholiday',
      name: 'TikTok',
    },
    pinterest: {
      handle: 'icelollyholiday',
      link: 'https://www.pinterest.co.uk/icelollyholiday',
      name: 'Pinterest',
    },
  },
};

export default config;
