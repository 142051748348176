import { ConfigHeader, HeaderTheme, NavigationTheme } from '@supersonic/config';

export const header: ConfigHeader = {
  logo: 'icelolly',
  blogSearchField: false,
  emailModalButton: true,
  navigation: {
    links: [
      {
        channel: 'Holidays',
        channelLink: '/holidays',
        subnav: [
          {
            title: 'Holidays',
            links: [
              {
                text: 'Cheap Holidays',
                link: '/',
              },
              {
                text: 'Package Holidays',
                link: '/holidays',
              },
              {
                text: 'Last-minute Holidays',
                link: '/holidays/last-minute',
              },
              {
                text: 'Summer 2025 Holidays',
                link: '/holidays/summer',
              },
              {
                text: 'All-inclusive Holidays',
                link: '/holidays/all-inclusive',
              },
              {
                text: 'Self-catering Holidays',
                link: '/holidays/self-catering',
              },
              {
                text: 'City Breaks',
                link: '/city',
              },
              {
                text: 'Cruise Holidays',
                link: '/holidays/cruise',
              },
              {
                text: 'Disney Holidays',
                link: '/holidays/disney',
              },
              {
                text: 'Free Child Places',
                link: '/holidays/free-child-places',
              },
            ],
          },
        ],
        cards: [
          {
            tag: '',
            title: 'Cheap Holidays',
            subText: '',
            image: 'navigation-cards/holidays/gran-canaria.jpg',
            link: '/holidays',
          },
          {
            tag: '',
            title: 'Last-minute Holidays',
            subText: '',
            image: 'navigation-cards/holidays/tenerife.jpg',
            link: '/holidays/last-minute',
          },
          {
            tag: '',
            title: 'All-inclusive Holidays',
            subText: '',
            image: 'navigation-cards/holidays/turkey.jpg',
            link: '/holidays/all-inclusive',
          },
        ],
      },
      {
        channel: 'Top Deals',
        channelLink: '/deals',
        subnav: [
          {
            title: 'Top Deals',
            links: [
              {
                text: 'All Deals',
                link: '/deals',
              },
              {
                text: 'All-inclusive Deals',
                link: '/deals?boardBasis=AI',
              },
              {
                text: 'Last-minute Deals',
                link: '/deals?theme=last-minute',
              },
              {
                text: 'UK Deals',
                link: '/deals?destination=uk',
              },
              {
                text: 'Florida Deals',
                link: '/deals?destination=florida',
              },
              {
                text: 'Greek Island Deals',
                link: '/deals?destination=greekIslands',
              },
              {
                text: 'Tenerife Deals',
                link: '/deals?destination=tenerife',
              },
              {
                text: 'Turkey Deals',
                link: '/deals?destination=turkey',
              },
              {
                text: 'Adult Only Deals',
                link: '/deals?theme=adults-only',
              },
            ],
          },
        ],
        cards: [
          {
            tag: '',
            title: 'All Deals',
            subText: '',
            image: 'navigation-cards/top-deals/corfu.jpg',
            link: '/deals',
          },
          {
            tag: '',
            title: 'All-inclusive Deals',
            subText: '',
            image: 'navigation-cards/top-deals/kefalonia.jpg',
            link: '/deals?boardBasis=AI',
          },
          {
            tag: '',
            title: 'Last-minute Deals',
            subText: '',
            image: 'navigation-cards/top-deals/algarve.jpg',
            link: '/deals?theme=last-minute',
          },
        ],
      },
      {
        channel: 'Destinations',
        channelLink: '/destinations',
        subnav: [
          {
            title: 'Destinations',
            links: [
              {
                text: 'Amsterdam',
                link: '/destinations/netherlands/amsterdam',
              },
              {
                text: 'Benidorm',
                link: '/destinations/spain/costa-blanca/benidorm',
              },
              {
                text: 'Cape Verde',
                link: '/destinations/cape-verde',
              },
              {
                text: 'Dubai',
                link: '/destinations/united-arab-emirates/dubai',
              },
              {
                text: 'Greece',
                link: '/destinations/greece',
              },
              {
                text: 'Iceland',
                link: '/destinations/iceland',
              },
              {
                text: 'Malta',
                link: '/destinations/malta',
              },
              {
                text: 'Portugal',
                link: '/destinations/portugal',
              },
              {
                text: 'Spain',
                link: '/destinations/spain',
              },
              {
                text: 'Tenerife',
                link: '/destinations/spain/canary-islands/tenerife',
              },
              {
                text: 'UK',
                link: '/uk',
              },
            ],
          },
        ],
        cards: [
          {
            tag: '',
            title: 'Turkey',
            subText: '',
            image: 'navigation-cards/destinations/turkey.jpg',
            link: '/destinations/turkey',
          },
          {
            tag: '',
            title: 'Majorca',
            subText: '',
            image: 'navigation-cards/destinations/majorca.jpg',
            link: '/destinations/spain/balearic-islands/majorca',
          },
          {
            tag: '',
            title: 'Lanzarote',
            subText: '',
            image: 'navigation-cards/destinations/lazarote.jpg',
            link: '/destinations/spain/canary-islands/lanzarote',
          },
        ],
      },
      {
        channel: 'Travel Info',
        channelLink: '/blog',
        subnav: [
          {
            title: 'Travel Info',
            links: [
              {
                text: 'Holiday Inspiration',
                link: '/blog',
              },
              {
                text: 'Activities',
                link: 'https://activities.icelolly.com/uk?utm_campaign=other&utm_medium=other&utm_source=other&aid=icelolly',
                linkRel: 'nofollow',
              },
              {
                text: 'Protection Information',
                link: '/protection-information',
              },
              {
                text: 'About Us',
                link: '/about-us',
              },
              {
                text: 'FAQs',
                link: '/faqs',
              },
            ],
          },
        ],
        cards: [
          {
            tag: '',
            title: 'Top Spots For An All-inclusive Holiday',
            subText: '',
            image: 'navigation-cards/travel-info/spain.jpg',
            link: '/blog/the-most-popular-destinations-for-an-all-inclusive-holiday',
          },
          {
            tag: '',
            title: 'Top 10 Cheap City Breaks in Europe',
            subText: '',
            image: 'navigation-cards/travel-info/barcelona.jpg',
            link: '/blog/top-10-cheap-city-breaks-in-europe',
          },
          {
            tag: '',
            title: 'Amazing Destinations To Visit This Year',
            subText: '',
            image: 'navigation-cards/travel-info/taormina.jpg',
            link: '/blog/amazing-holiday-destinations-to-visit-this-year',
          },
        ],
      },
      {
        channel: 'Call to Book',
        channelLink: '/call-to-book',
      },
    ],
  },
};

export const headerTheme: HeaderTheme = {
  backgroundColor: 'primary',
  logoColor: 'white',
  logoHoverColor: 'primary_lighter4',
  navToggleBtnColor: 'white',
};

export const navigationTheme: NavigationTheme = {
  borderColor: 'primary_lighter4',
  focusColor: 'white',
  linkColor: 'white',
  linkHoverColor: 'primary_lighter4',
  chevronColor: 'white',
  chevronHoverColor: 'primary_lighter4',
  mobileChevronColor: 'primary',
  subnav: {
    chevronColor: 'primary',
    headingColor: 'primary',
  },
  card: {
    tagColor: 'primary',
  },
};
